.buttonWrap{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 30px;
    align-items: center;
    gap: 30px;
}

.buttonStyle{
    background-color: black;
    width: auto;
    padding-left:  20px;
    border: none;
    color: white;
    padding-right: 20px;
    cursor: pointer;
    height: 50px;
    border-radius: 10px;
    font-family: 'DM Sans', sans-serif;
    font-size: 18px;
    margin-top: 10px;
}