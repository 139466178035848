@media screen and (min-width:451px){
    .mainHeader{
        height: 80px;
        box-shadow: 1px 1px 5px 0px rgb(0 0 0 / 50%);
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        color: white;
        align-items: center;
        padding: 10px;
    }
    
    .mainHeader img {
        height: 50px;
        width: 50px;
    }


    .closeButton{
        display: none;
    }
    .menuHam{
        display: none;
    }
.mobileNavBarActive{
    display: none;
}

.mobileNavBar{
    display: none;
}


    .navMenu{
        display: flex;
        flex-direction: row;
        gap: 20px;
        color: white;
    }
    
    .navMenu p {
        cursor: pointer;
    
    }
    
    .connectButton{
        height: 40px;
        width: auto;
        padding: 8px;
        border-radius: 8px;
        min-width: 120px;
        background-color: #00bdf7;
        color: black;
        border: none;
    }
    
    .navMenu p:hover{
        color: #00bdf7;
    }
}



@media screen and (max-width:450px){
    .mainHeader{
        height: 80px;
        box-shadow: 1px 1px 5px 0px rgb(0 0 0 / 50%);
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        color: white;
        align-items: center;
        padding: 10px;
    }
    
    .mainHeader img {
        height: 50px;
        width: 50px;
    }


    .navMenu {
        display: none;
    }
    
    .mobileNavBarActive{
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        height: 500%;
        transition: all 0.5s ease 0s;
        background-color: #202021;
        width: 100%;
        color: white;
        transform: translate(0%);
    }
    
    .mobileNavBarActive p {
        cursor: pointer;
        margin-left: 30px;
    }

    .mobileNavBar{
        transition: all 0.5s ease 0s;
        height: 0px;
        transform: translate(-100%);
    }

    .closeButton{
        margin-left: 90%;
        margin-top: 20px;
    }
    
    .connectButton{
        height: 40px;
        width: auto;
        padding: 8px;
        border-radius: 8px;
        min-width: 120px;
        background-color: #00bdf7;
        color: black;
        border: none;
    }
    
    .mobileNavBarActive p:hover{
        color: #00bdf7;
    }

}