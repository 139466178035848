.mainStakingBox{
    display: block;
    margin: auto;
    margin-top: 120px;
    width: 90%;
    height: auto;
    padding-bottom: 80px;
    border-radius: 10px;
    background-color: #00bdf7;
}

.userStakeDetails{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10%;

}

.stakeDetailBox{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.stakeDetailBox h4 {
    font-size: 25px;
}