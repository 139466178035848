  .firstInputFormWrap{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      gap: 20px;
  }

  .inputDiv{
      display: flex;
      flex-direction: column;
      color: black;
      border-radius: 5px;
      padding: 10px;
      border: none;
      width: 45%;
  }

  .inputDiv p {
      font-size: 13px;
  }

  .inputDiv input{
    color: black;
    height: 40px;
    padding-left: 10px;
    float: right;
    width: 90%;
    background-color: white;
    border-radius: 5px;
    border: none;
  }

  .inputDiv div {
    color: black;
    height: 40px;
    float: right;
    font-size: 15px;
    width: 90%;
    padding-left: 20px;
    background-color: white;
    border-radius: 5px;
    border: none;
  }

  .calculatorResultDiv{
      display: flex;
      width: 90%;
      margin: auto;
      flex-wrap: wrap;
      border-radius: 8px;
      justify-content: center;
      gap: 20px;
      background-color:#202021 ;
      padding: 10px;
      height: auto;
      padding-bottom: 30px;
      padding-top: 30px;
      
      gap: 30px;
  }


  .calculateButton{
    color: white;
    height: 40px;
    float: right;
    width:45%;
    text-align: center;
    margin-right: 5px;
    margin-top: 45px;
    background-color: black;
    border-radius: 5px;
    border: none;
  }