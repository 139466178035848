.stakingMenu{
    display: flex;
    justify-content:center;
    flex-direction: row;
    gap: 20px;
    cursor: pointer;
}

.menuBar{
    height: 3px;
    width: auto;
    background-color: black;
    padding: 0 20px;
    margin-top: -10px;
    border-radius: 10px;
}