.mainPage{
    padding: 10px;
    margin-top: 40px;
}

.metricsWrap{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.contractBox{
    display: flex;
    flex-direction: column;
    align-items: center;
    border-color: #ccc;
    border-width: 1px;
    border-radius: 10px;
    border-style: dashed;
    padding: 40px;
    width: 80%;
    word-break: break-all;
    height: auto;
    padding-top: 30px;
    padding-bottom: 30px;
}

.contractBox p {
    text-align: center;
    color: white;
}