@media screen and (min-width:768px){
  .mainDiv{
    display: block;
    margin: auto;
    background-color: transparent;
    height:100px;
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    margin-top: 30px;
}

.topMenu{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap :10%
}

.highlightDiv{
    display: flex;
    margin-top: 10px;
    background-color: #49d1c9;
      width: 180px;
      height: 50px;
      border-radius: 30px;
      border:none;
      color: white;
      justify-content: center;
      align-items: center;
      font-family: 'DM Sans', sans-serif;
      font-weight: 700;
      font-size: 15px;
  }

  .averageIntoText{
    color:  white;
    font-size: 18px;
    text-align: center;
  }

  .inputDiv{
    display: flex;
    flex-direction: row;
    height: 50px;
    width: 70%;
    border-radius: 10px;
    background-color: black;
    border: none;
  }

  .inputDiv p {
    color:  white;
    cursor: pointer;
  }

  .inputForm{
      width: 90%;
      padding: 5px;
      border-radius: 10px;
      background-color: black;
      font-size: 18px;
      color: white;
      border: none;
  }

  .inputForm ::placeholder{
    padding-left:30px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 900;
    font-size: 15px;
  }


  .inputDiv p {
      font-weight: bolder;
      padding-right: 10px;
  }

  .inputForm:focus{
      outline: none;
  }

  .actionDiv{
    width: 40%;
    margin-top: -10px;
  }

  .actionButtonFill{
    background-color: black ;
        width: 90%;
        padding-left:  20px;
        color: white;
        border: none;
        padding-right: 20px;
        cursor: pointer;
        height: 50px;
        border-radius: 10px;
        font-family: 'DM Sans', sans-serif;
        font-size: 18px;
        margin-top: 10px;
  }

}

@media screen and (max-width:767px){
  .mainDiv{
    display: block;
    margin: auto;
    background-color: transparent;
    height:100px;
    width: 90%;
    padding: 20px;
    border-radius: 10px;
    margin-top: 30px;
}

.topMenu{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap :10%
}

.highlightDiv{
    display: flex;
    margin-top: 10px;
    background-color: #aeb4b7;
      width: 180px;
      height: 50px;
      border-radius: 30px;
      border:none;
      color: white;
      justify-content: center;
      align-items: center;
      font-family: 'DM Sans', sans-serif;
      font-weight: 700;
      font-size: 15px;
  }

  .averageIntoText{
    color:  white;
    font-size: 18px;
    text-align: center;
  }

  .inputDiv{
    display: flex;
    flex-direction: row;
    height: 50px;
    width: 70%;
    border-radius: 10px;
    background-color: black;
    border: none;
  }

  .inputDiv p {
    color:  black;
  }

  .inputForm{
      width: 90%;
      border-radius: 10px;
      background-color: black;
      font-size: 18px;
      color: white;
      border: none;
  }

  .inputForm ::placeholder{
    padding-left:30px;
    font-family: 'DM Sans', sans-serif;
    font-weight: 900;
    font-size: 15px;
  }


  .inputDiv p {
      font-weight: bolder;
      padding-right: 10px;
  }

  .inputForm:focus{
      outline: none;
  }

  .actionDiv{
    width: 40%;
    margin-top: -10px;
  }

  .actionButtonFill{
    background-color: black;
        width: auto;
        padding-left:  20px;
        border: none;
        color: white;
        padding-right: 20px;
        cursor: pointer;
        height: 50px;
        border-radius: 10px;
        font-family: 'DM Sans', sans-serif;
        font-size: 18px;
        margin-top: 10px;
  }

}

