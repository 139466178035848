.mainCard{
    height: 200px;
    display: flex;
    flex-direction: column;
    width: 400px;
    background-color: #00bdf7;
    justify-content: center;
    border-radius: 8px;
    text-align: center;
}

.mainCard h3 {
    font-size: 23px;
}


.claimButton{
    display: block;
    margin: auto;
    height: 40px;
    width: 50%;
    padding: 8px;
    border-radius: 8px;
    min-width: 120px;
    background-color: #202021 ;
    color: white;
    border: none;
}